import { FederationRuntimePlugin } from "@module-federation/runtime/types";
import versions from "./versions.json";

const stage = process.env.REACT_APP_STAGE || "dev";

const prodDomainNameByStage: Record<string, string> = {
  dev: "cnapp-ui-dev.eticloud.io",
  staging: "cnapp-ui-staging.eticloud.io",
  production: "cnapp-ui-production.eticloud.io",
};

const mfeManagementDomain: Record<string, string> = {
  dev: "https://api.dev.lightspin.panoptica.app",
  staging: "https://api.staging.lightspin.panoptica.app",
  production: "https://api.us1.console.panoptica.app",
  productionEU: "https://api.eu1.console.panoptica.app",
};

type ActiveVersionsDetails = {
  version: string;
};

type ActiveVersions = Record<string, ActiveVersionsDetails>;
let updateremoteEntries;

const convertStringToSet = (delimitedString: string): Set<string> => {
  const elements = delimitedString
    .split(",")
    .map((item) => item.trim())
    .filter((item) => item !== "");
  return new Set(elements);
};

const updateActiveVersions = (activeVersions: ActiveVersions) => {
  localStorage.setItem("hostActiveVersions", JSON.stringify(activeVersions));
};

async function fetchData(url, fetchOptions) {
  try {
    const response = await fetch(url, fetchOptions);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    updateActiveVersions(data);
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    const data = {};
    return data;
  }
}

async function fetchActiveVersions() {
  const url = `${mfeManagementDomain[stage]}/api/scs/mfe-management/mfe/active-versions/host`;

  const fetchOptions: RequestInit = {
    method: "GET",
  };

  // Fetch the active versions for the MFEs
  return await fetchData(url, fetchOptions);
}

let fetchedVersions = fetchActiveVersions();

const CustomPlugin = (): FederationRuntimePlugin => {
  const isDevPreview = localStorage.getItem("isDevPreview") === "true";
  const activePreviewedVersions = JSON.parse(
    localStorage.getItem("activePreviewedVersions") || "{}",
  );

  return {
    name: "backend-remote-control",
    beforeRequest: async (args) => {
      // Await the fetched versions before continuing
      args.options.remotes = await updateremoteEntries;

      return args;
    },
    init: async (args) => {
      // Fetch the active versions for the MFEs
      updateremoteEntries = fetchedVersions.then(() => {
        return Promise.all([
          ...args.options.remotes.map(async (remote) => {
            const remoteAlias = remote.alias || "";
            const localMfesSet = convertStringToSet(
              process.env.mfe_local || "",
            );
            const previewVersion = activePreviewedVersions[remoteAlias];
            const isPreviewOn = !!(isDevPreview && previewVersion);
            const activeVersions = localStorage.getItem("hostActiveVersions");
            const parsedActiveVersions = activeVersions
              ? JSON.parse(activeVersions)
              : null;
            const version =
              (isPreviewOn && previewVersion) ||
              (parsedActiveVersions &&
                parsedActiveVersions[remoteAlias]?.version) ||
              //TODO: update using logRocket that version was loaded from versions[stage][remote.alias]
              versions[stage][remoteAlias];
            if (!isPreviewOn && (localMfesSet?.has(remote.name) || !version)) {
              return remote;
            }
            remote[
              "entry"
            ] = `https://${remoteAlias}.${prodDomainNameByStage[stage]}/${version}/remoteEntry.js`;

            return remote;
          }),
        ]);
      });

      return args;
    },
    beforeLoadShare: async (args) => {
      //@ts-ignore
      while (__FEDERATION__.__INSTANCES__.length <= 1) {
        // workaround to bug thatll be fixed in next release
        await new Promise((r) => setTimeout(r, 150));
      }
      return args;
    },
  };
};

export default CustomPlugin;
